import { useGlobal } from 'reactn';

function UpdateLayoutsForReviewZones(copyOfZoneLayouts) {
  const [setupFieldChoices] = useGlobal('setupFieldChoices');

  copyOfZoneLayouts.map((zones) => {
    zones.layouts.map((layout) => {
      if (setupFieldChoices.ledType === 'White') {
        if (setupFieldChoices.driverRequired === 'JCC Driver') {
          if (setupFieldChoices.wattage === 4.8) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              layout.fields[0].value = 'Non-Dimmable Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 2500) {
                layout.fields[0].SKU = 'BC020009';
                layout.fields[0].wattage = '12W';
                layout.fields[0].price = 50.5;
              } else if (
                layout.stripLength > 2500 &&
                layout.stripLength <= 6250
              ) {
                layout.fields[0].SKU = 'BC020012';
                layout.fields[0].wattage = '30W';
                layout.fields[0].price = 130;
              } else if (
                layout.stripLength > 6250 &&
                layout.stripLength <= 15600
              ) {
                layout.fields[0].SKU = 'BC020013';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 213;
              } else {
                layout.fields[0].SKU = 'BC020007';
                layout.fields[0].wattage = '180W';
                layout.fields[0].price = 224.5;
              }
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              layout.fields[0].value = 'Dimmable (Triac) Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 5200) {
                layout.fields[0].SKU = 'BC020010';
                layout.fields[0].wattage = '25W';
                layout.fields[0].price = 94.5;
              } else if (
                layout.stripLength > 5200 &&
                layout.stripLength <= 10000
              ) {
                layout.fields[0].SKU = 'BC020011';
                layout.fields[0].wattage = '50W';
                layout.fields[0].price = 179.5;
              } else {
                layout.fields[0].SKU = 'BC020001';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 398;
              }
            }
          }
        
          if (setupFieldChoices.wattage === 8) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              layout.fields[0].value = 'Non-Dimmable Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 1500) {
                layout.fields[0].SKU = 'BC020009';
                layout.fields[0].wattage = '12W';
                layout.fields[0].price = 50.5;
              } else if (
                layout.stripLength > 1500 &&
                layout.stripLength <= 3750
              ) {
                layout.fields[0].SKU = 'BC020012';
                layout.fields[0].wattage = '30W';
                layout.fields[0].price = 130;
              } else if (
                layout.stripLength > 3750 &&
                layout.stripLength <= 9350
              ) {
                layout.fields[0].SKU = 'BC020013';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 213;
              } else {
                layout.fields[0].SKU = 'BC020007';
                layout.fields[0].wattage = '180W';
                layout.fields[0].price = 224.5;
              }
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              layout.fields[0].value = 'Dimmable (Triac) Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 3100) {
                layout.fields[0].SKU = 'BC020010';
                layout.fields[0].wattage = '25W';
                layout.fields[0].price = 94.5;
              } else if (
                layout.stripLength > 3100 &&
                layout.stripLength <= 6250
              ) {
                layout.fields[0].SKU = 'BC020011';
                layout.fields[0].wattage = '50W';
                layout.fields[0].price = 179.5;
              } else {
                layout.fields[0].SKU = 'BC020001';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 398;
              }
            }
          }
          if (setupFieldChoices.wattage === 9.6) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              layout.fields[0].value = 'Non-Dimmable Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 1250) {
                layout.fields[0].SKU = 'BC020009';
                layout.fields[0].wattage = '12W';
                layout.fields[0].price = 50.5;
              } else if (
                layout.stripLength > 1250 &&
                layout.stripLength <= 3100
              ) {
                layout.fields[0].SKU = 'BC020012';
                layout.fields[0].wattage = '30W';
                layout.fields[0].price = 130;
              } else if (
                layout.stripLength > 3100 &&
                layout.stripLength <= 7800
              ) {
                layout.fields[0].SKU = 'BC020013';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 213;
              } else {
                layout.fields[0].SKU = 'BC020007';
                layout.fields[0].wattage = '180W';
                layout.fields[0].price = 224.5;
              }
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              layout.fields[0].value = 'Dimmable (Triac) Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 2600) {
                layout.fields[0].SKU = 'BC020010';
                layout.fields[0].wattage = '25W';
                layout.fields[0].price = 94.5;
              } else if (
                layout.stripLength > 2600 &&
                layout.stripLength <= 5200
              ) {
                layout.fields[0].SKU = 'BC020011';
                layout.fields[0].wattage = '50W';
                layout.fields[0].price = 179.5;
              } else {
                layout.fields[0].SKU = 'BC020001';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 398;
                layout.maxLength = 7800;
              }
            }
          }
          if (setupFieldChoices.wattage === 14.4) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              layout.fields[0].value = 'Non-Dimmable Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 800) {
                layout.fields[0].SKU = 'BC020009';
                layout.fields[0].wattage = '12W';
                layout.fields[0].price = 50.5;
              } else if (
                layout.stripLength > 800 &&
                layout.stripLength <= 2000
              ) {
                layout.fields[0].SKU = 'BC020012';
                layout.fields[0].wattage = '30W';
                layout.fields[0].price = 130;
              } else if (
                layout.stripLength > 2000 &&
                layout.stripLength <= 5200
              ) {
                layout.fields[0].SKU = 'BC020013';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 213;
              } else {
                layout.fields[0].SKU = 'BC020007';
                layout.fields[0].wattage = '180W';
                layout.fields[0].price = 224.5;
              }
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              layout.fields[0].value = 'Dimmable (Triac) Driver';
              layout.fields[0].iprating = 'IP20';
              if (layout.stripLength <= 1700) {
                layout.fields[0].SKU = 'BC020010';
                layout.fields[0].wattage = '25W';
                layout.fields[0].price = 94.5;
              } else if (
                layout.stripLength > 1700 &&
                layout.stripLength <= 3400
              ) {
                layout.fields[0].SKU = 'BC020011';
                layout.fields[0].wattage = '50W';
                layout.fields[0].price = 179.5;
              } else {
                layout.fields[0].SKU = 'BC020001';
                layout.fields[0].wattage = '75W';
                layout.fields[0].price = 398;
                layout.maxLength = 5200;
              }
            }
          }
        } else {
          // Set entry for No driver required
          layout.fields[0].value = 'No Driver supplied';
          layout.fields[0].SKU = '';
          layout.fields[0].wattage = '';
          layout.fields[0].price = 0;
          layout.fields[0].iprating = '';
        }
      }

      if (setupFieldChoices.ledType === 'RGBW') {
        if (setupFieldChoices.driverRequired === 'JCC Driver') {
          if (setupFieldChoices.wattage === 7.2) {
            if (layout.stripLength <= 1600) {
              layout.fields[0].SKU = 'BC020009';
              layout.fields[0].wattage = '12W';
              layout.fields[0].price = 50.5;
            } else if (layout.stripLength > 1600 && layout.stripLength <= 4150) {
              layout.fields[0].SKU = 'BC020012';
              layout.fields[0].wattage = '30W';
              layout.fields[0].price = 130;
            } else if (layout.stripLength > 4150 && layout.stripLength <= 10000) {
              layout.fields[0].SKU = 'BC020013';
              layout.fields[0].wattage = '75W';
              layout.fields[0].price = 213;
            } else {
              layout.fields[0].SKU = 'BC020007';
              layout.fields[0].wattage = '180W';
              layout.fields[0].price = 224.5;
            }
          }

          if (setupFieldChoices.wattage === 17.6) {
            if (layout.stripLength <= 650) {
              layout.fields[0].SKU = 'BC020009';
              layout.fields[0].wattage = '12W';
              layout.fields[0].price = 50.5;
            } else if (layout.stripLength > 650 && layout.stripLength <= 1700) {
              layout.fields[0].SKU = 'BC020012';
              layout.fields[0].wattage = '30W';
              layout.fields[0].price = 130;
            } else if (layout.stripLength > 1700 && layout.stripLength <= 4250) {
              layout.fields[0].SKU = 'BC020013';
              layout.fields[0].wattage = '75W';
              layout.fields[0].price = 213;
            } else {
              layout.fields[0].SKU = 'BC020007';
              layout.fields[0].wattage = '180W';
              layout.fields[0].price = 224.5;
            }
          }
        } else {
          // Set entry for No driver required
          layout.fields[0].value = 'No Driver supplied';
          layout.fields[0].SKU = '';
          layout.fields[0].wattage = '';
          layout.fields[0].price = 0;
          layout.fields[0].iprating = '';
        }

        layout.fields[1].value = 'RGBW DC Cable';

        if (
          setupFieldChoices.remoteType !== 'No Remote or Controller Required'
        ) {
          // Below DC Cable is added for JCC Driver OR Other Driver options (i.e. always with remotes)

          layout.fields.splice(1, 0, {
            title: 'DC Cable to RF Controller',
            SKU: 'JC020035',
            length: 1000,
            quantity: 20,
            price: 6,
          });

          if (setupFieldChoices.remoteType === 'Handheld Remote') {
            layout.fields.splice(2, 0, {
              title: 'RF Controller',
              SKU: 'JC121448',
              price: 107.5,
            });
          }
          if (setupFieldChoices.remoteType === 'Wall Mounted Remote') {
            layout.fields.splice(2, 0, {
              title: 'RF Controller',
              SKU: 'JC121450',
              price: 94.5,
            });
          }
        }
      }

      // Profile details...
      //
      if (setupFieldChoices.profile !== 'No Profile') {
        let profileLength = Math.ceil(layout.stripLength / 500) * 500;
        let numberOfProfilePieces = 0;

        if (profileLength - layout.stripLength < 100) {
          profileLength = profileLength + 500;
        }

        if (setupFieldChoices.profile === 'Corner') {
          if (profileLength / 2000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 2000);

            layout.fields.push({
              title: 'Corner Profile 2m',
              SKU: 'JC121267',
              length: numberOfProfilePieces * 2000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 76.50,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 2000;
          }

          if (profileLength / 1500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1500);

            layout.fields.push({
              title: 'Corner Profile 1.5m',
              SKU: 'JC121370',
              length: numberOfProfilePieces * 1500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 72,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1500;
          }

          if (profileLength / 1000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1000);

            layout.fields.push({
              title: 'Corner Profile 1m',
              SKU: 'JC121369',
              length: numberOfProfilePieces * 1000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 48.5,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1000;
          }

          if (profileLength / 500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 500);

            layout.fields.push({
              title: 'Corner Profile 0.5m',
              SKU: 'JC121368',
              length: numberOfProfilePieces * 500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 25,
              type: 'profile',
            });
          }
        }

        if (setupFieldChoices.profile === 'Surface Mounted') {
          if (profileLength / 2000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 2000);

            layout.fields.push({
              title: 'Surface Mounted Profile 2m',
              SKU: 'JC121265',
              length: numberOfProfilePieces * 2000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 101,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 2000;
          }

          if (profileLength / 1500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1500);

            layout.fields.push({
              title: 'Surface Mounted Profile 1.5m',
              SKU: 'JC121364',
              length: numberOfProfilePieces * 1500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 86,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1500;
          }

          if (profileLength / 1000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1000);

            layout.fields.push({
              title: 'Surface Mounted Profile 1m',
              SKU: 'JC121363',
              length: numberOfProfilePieces * 1000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 57,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1000;
          }

          if (profileLength / 500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 500);

            layout.fields.push({
              title: 'Surface Mounted Profile 0.5m',
              SKU: 'JC121362',
              length: numberOfProfilePieces * 500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 28.5,
              type: 'profile',
            });
          }
        }

        if (setupFieldChoices.profile === 'Slimline Surface Mounted') {
          if (profileLength / 2000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 2000);

            layout.fields.push({
              title: 'Slimline Surface Mounted Profile 2m',
              SKU: 'JC121378',
              length: numberOfProfilePieces * 2000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 113.5,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 2000;
          }

          if (profileLength / 1500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1500);

            layout.fields.push({
              title: 'Slimline Surface Mounted Profile 1.5m',
              SKU: 'JC121377',
              length: numberOfProfilePieces * 1500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 86,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1500;
          }

          if (profileLength / 1000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1000);

            layout.fields.push({
              title: 'Slimline Surface Mounted Profile 1m',
              SKU: 'JC121376',
              length: numberOfProfilePieces * 1000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 57,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1000;
          }

          if (profileLength / 500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 500);

            layout.fields.push({
              title: 'Slimline Surface Mounted Profile 0.5m',
              SKU: 'JC121375',
              length: numberOfProfilePieces * 500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 28.5,
              type: 'profile',
            });
          }
        }

        if (setupFieldChoices.profile === 'Recessed') {
          if (profileLength / 2000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 2000);

            layout.fields.push({
              title: 'Recessed Profile 2m',
              SKU: 'JC121266',
              length: numberOfProfilePieces * 2000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 96,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 2000;
          }

          if (profileLength / 1500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1500);

            layout.fields.push({
              title: 'Recessed Profile 1.5m',
              SKU: 'JC121367',
              length: numberOfProfilePieces * 1500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 90.5,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1500;
          }

          if (profileLength / 1000 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 1000);

            layout.fields.push({
              title: 'Recessed Profile 1m',
              SKU: 'JC121366',
              length: numberOfProfilePieces * 1000,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 60,
              type: 'profile',
            });

            profileLength = profileLength - numberOfProfilePieces * 1000;
          }

          if (profileLength / 500 >= 1) {
            numberOfProfilePieces = Math.floor(profileLength / 500);

            layout.fields.push({
              title: 'Recessed Profile 0.5m',
              SKU: 'JC121365',
              length: numberOfProfilePieces * 500,
              quantity: numberOfProfilePieces,
              price: numberOfProfilePieces * 30,
              type: 'profile',
            });
          }
        }
      }

      if (layout.id !== undefined) {
        delete layout.id;
      }
      if (layout.current !== undefined) {
        delete layout.current;
      }
      if (layout.complete !== undefined) {
        delete layout.complete;
      }

      let newLayoutPrice = 0;

      layout.fields.map((field, index) => {
        newLayoutPrice = newLayoutPrice + field.price;

        if (field.id !== undefined) {
          delete field.id;
        }
        if (field.current !== undefined) {
          delete field.current;
        }
        if (field.disabled !== undefined) {
          delete field.disabled;
        }
        if (field.instruction !== undefined) {
          delete field.instruction;
        }
        if (field.options !== undefined) {
          delete field.options;
        }
        if (field.showaddlayoutbutton !== undefined) {
          delete field.showaddlayoutbutton;
        }
        if (field.showdeletelayoutbutton !== undefined) {
          delete field.showdeletelayoutbutton;
        }
        if (field.showeditlayoutbutton !== undefined) {
          delete field.showeditlayoutbutton;
        }
        if (field.showendlayoutbutton !== undefined) {
          delete field.showendlayoutbutton;
        }
        if (field.showfinishbutton !== undefined) {
          delete field.showfinishbutton;
        }
        if (field.showaddnextpiecebutton !== undefined) {
          delete field.showaddnextpiecebutton;
        }
        if (field.showremovepiecebutton !== undefined) {
          delete field.showremovepiecebutton;
        }

        return null;
      });

      layout.price = newLayoutPrice;

      return null;
    });

    return null;
  });

  return copyOfZoneLayouts;
}

export default UpdateLayoutsForReviewZones;
