import customZoneLayout from '../../config/customzonelayout.json';

function CreateCustomZoneLayout(setupFieldChoices) {
  const tempZoneLayout = customZoneLayout;
  let copyOfZoneLayout = JSON.parse(JSON.stringify(tempZoneLayout));

  if (setupFieldChoices.driverRequired === 'JCC Driver') {
    if (setupFieldChoices.ledType === 'White') {
      if (setupFieldChoices.wattage === 4.8) {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 2500) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 2500 &&
            setupFieldChoices.stripLength <= 6250
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-75W.jpg';
          }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 5200) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 5200 &&
            setupFieldChoices.stripLength <= 10000
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
          }
        }
      }
    
      if (setupFieldChoices.wattage === 8) {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 1500) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1500 &&
            setupFieldChoices.stripLength <= 3750
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
            } else if (
              setupFieldChoices.stripLength > 3750 &&
              setupFieldChoices.stripLength <= 9350
            ) {
              copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
              copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
              copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
                'driver-nondim-IP20-75W.jpg';
            } else {
              copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020007';
              copyOfZoneLayout[0].layouts[0].fields[0].wattage = '180W';
              copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
                'driver-nondim-IP20-180W.jpg';
            }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 3100) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 3100 &&
            setupFieldChoices.stripLength <= 6250
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
          }
        }
      }

      if (setupFieldChoices.wattage === 9.6) {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 1250) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1250 &&
            setupFieldChoices.stripLength <= 3100
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
          } else if (
            setupFieldChoices.stripLength > 3100 &&
            setupFieldChoices.stripLength <= 7800
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-75W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020007';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '180W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-180W.jpg';
          }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 2600) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 2600 &&
            setupFieldChoices.stripLength <= 5200
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
          }
        }
      }

      if (setupFieldChoices.wattage === 14.4) {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 800) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 800 &&
            setupFieldChoices.stripLength <= 2000
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
          } else if (
            setupFieldChoices.stripLength > 2000 &&
            setupFieldChoices.stripLength <= 5200
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-75W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020007';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '180W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-180W.jpg';
          }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 1700) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1700 &&
            setupFieldChoices.stripLength <= 3400
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
           }
        }
      }
    }

    if (setupFieldChoices.ledType === 'RGBW') {
      // Note all RGBW drivers are Non-dimmable
      copyOfZoneLayout[0].layouts[0].fields[0].value = 'Non-Dimmable Driver';
      copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
        'Non-Dimmable Driver';
      copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
        'IP20 / 24V';

      if (setupFieldChoices.wattage === 7.2) {
        if (setupFieldChoices.stripLength <= 1600) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1600 &&
            setupFieldChoices.stripLength <= 4150
          ) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-30W.jpg';
        } else {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-75W.jpg';
        }
      }

      if (setupFieldChoices.wattage === 17.6) {
        if (setupFieldChoices.stripLength <= 650) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 650 &&
            setupFieldChoices.stripLength <= 2050
          ) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-30W.jpg';
        } else {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-75W.jpg';
        }
      }
    }
  } else {
    // Remove DC Cable from order IF navigating from Single Length
    if (!setupFieldChoices.customLayout) {
      delete copyOfZoneLayout[0].layouts[0].fields[1];
    }

    // Set entry for No driver required
    copyOfZoneLayout[0].layouts[0].fields[0].title = 'Other Driver';
    copyOfZoneLayout[0].layouts[0].fields[0].value = 'No Driver supplied';
    copyOfZoneLayout[0].layouts[0].fields[0].instruction =
      'You have opted to use your own 24V driver. We will calculate the minimum wattage rating for your layout based on the total length of your LED Strip';
    copyOfZoneLayout[0].layouts[0].fields[0].SKU = '';
    copyOfZoneLayout[0].layouts[0].fields[0].wattage = '';
    copyOfZoneLayout[0].layouts[0].fields[0].voltage = '';
    copyOfZoneLayout[0].layouts[0].fields[0].price = 0;
    copyOfZoneLayout[0].layouts[0].fields[0].iprating = '';

    copyOfZoneLayout[0].layouts[0].fields[0].options[0].id = 'Other Driver';
    copyOfZoneLayout[0].layouts[0].fields[0].options[0].title = 'Other Driver';
    copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
      'You will require a 24V constant voltage driver';
    copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
      'other-driver-blue.jpg';
  }

  if (setupFieldChoices.driverRequired === 'JCC Driver') {
    copyOfZoneLayout[0].layouts[0].fields[0].instruction =
      "Your driver type has been selected based on your setup choices. We'll automatically specify a driver with the most economical wattage rating for your layout based on the total length of your LED Strip.";
  } else {
    copyOfZoneLayout[0].layouts[0].fields[0].instruction =
      "You have selected to supply your own 24V constant voltage driver in your setup choices. We'll calculate the minimum wattage rating required for your layout based on the total length of your LED Strip.";
  }

  if (setupFieldChoices.ledType === 'RGBW') {
    if (
      setupFieldChoices.remoteType === 'Handheld Remote' &&
      setupFieldChoices.remoteQuantity > 0
    ) {
      copyOfZoneLayout[0].layouts[0].fields[0].options.push({
        title: 'Controller for Handheld Remote',
        description: 'Can power up to 3 zones',
        selected: true,
        deselected: false,
        imagepath: 'JC121448-controller-for-hand-held-remote.jpg',
        SKU: 'JC121448',
        price: 107.5,
      });
    }
    if (
      setupFieldChoices.remoteType === 'Wall Mounted Remote' &&
      setupFieldChoices.remoteQuantity > 0
    ) {
      copyOfZoneLayout[0].layouts[0].fields[0].options.push({
        title: 'Controller for Wall Mounted Remote',
        description: 'Can power up to 4 zones',
        selected: true,
        deselected: false,
        imagepath: 'JC121450-controller-for-wall-mounted-remote.jpg',
        SKU: 'JC121450',
        price: 94.5,
      });
    }

    if (setupFieldChoices.driverRequired === 'JCC Driver') {
      copyOfZoneLayout[0].layouts[0].fields[0].instruction =
        "Your driver type has been selected based on your setup choices. We'll automatically specify a driver with the most economical wattage rating for your layout based on the total length of your LED Strip. 1m of DC Cable connecting the driver and controller will automatically be added to each layout.";
    } else {
      copyOfZoneLayout[0].layouts[0].fields[0].instruction =
        "You have selected to supply your own 24V constant voltage driver in your setup choices. We'll calculate the minimum wattage rating required for your layout based on the total length of your LED Strip. All RGBW strip is dimmable based on your driver selection.";
    }
  }

  // console.log('validation limit: ' + setupFieldChoices.validationlimit);

  copyOfZoneLayout[0].layouts[0].maxLength = setupFieldChoices.validationlimit;
  copyOfZoneLayout[0].layouts[0].lengthRemaining =
    setupFieldChoices.validationlimit - parseInt(setupFieldChoices.stripLength);

  return copyOfZoneLayout;
}

export default CreateCustomZoneLayout;
