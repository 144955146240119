import singleLengthZoneLayout from '../../config/singlelengthzonelayout.json';

function CreateSingleLengthZoneLayout(setupFieldChoices) {
  const tempZoneLayout = singleLengthZoneLayout;
  let copyOfZoneLayout = JSON.parse(JSON.stringify(tempZoneLayout));

  if (setupFieldChoices.ledType === 'White') {
    // Set number of pieces for strip
    copyOfZoneLayout[0].layouts[0].fields[2].quantity =
      setupFieldChoices.stripLength / 50;

    // Set validation divisor - min cut length
    copyOfZoneLayout[0].layouts[0].fields[2].options[0].validationdivisor = 50;

    // DC Cable / DC Cable Corner SKU: JC020035
    copyOfZoneLayout[0].layouts[0].fields[1].SKU = 'JC020035';
    copyOfZoneLayout[0].layouts[0].fields[1].options[0].price = 3;
    copyOfZoneLayout[0].layouts[0].fields[1].price = 3;

    if (setupFieldChoices.wattage === 4.8) {
      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 2500) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 2500 &&
            setupFieldChoices.stripLength <= 6250
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-75W.jpg';
          }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 5200) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 5200 &&
            setupFieldChoices.stripLength <= 10000
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
          }
        }
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020059';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-48-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 1.00;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020060';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-48-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 1.00;
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020075';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-48-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 1.20;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020076';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-48-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 1.20;
      }
    }
 
    if (setupFieldChoices.wattage === 8) {
      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 1500) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1500 &&
            setupFieldChoices.stripLength <= 3750
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
            } else if (
              setupFieldChoices.stripLength > 3750 &&
              setupFieldChoices.stripLength <= 9350
            ) {
              copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
              copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
              copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
                'driver-nondim-IP20-75W.jpg';
            } else {
              copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020007';
              copyOfZoneLayout[0].layouts[0].fields[0].wattage = '180W';
              copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
                'driver-nondim-IP20-180W.jpg';
            }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 3100) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 3100 &&
            setupFieldChoices.stripLength <= 6250
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
          }
        }
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020122';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-COB-8-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 0.80;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020124';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-COB-8-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 0.80;
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020123';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-COB-8-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 1.10;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020125';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-COB-8-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 1.10;
      }
    }
    if (setupFieldChoices.wattage === 9.6) {
      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 1250) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1250 &&
            setupFieldChoices.stripLength <= 3100
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
          } else if (
            setupFieldChoices.stripLength > 3100 &&
            setupFieldChoices.stripLength <= 7800
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-75W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020007';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '180W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-180W.jpg';
          }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 2600) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 2600 &&
            setupFieldChoices.stripLength <= 5200
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
          }
        }
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020063';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-96-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.60;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020064';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-96-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.60;
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020079';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-96-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.80;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020080';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-96-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.80;
      }
    }
    if (setupFieldChoices.wattage === 14.4) {
      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        if (setupFieldChoices.dimming === 'Non-dimmable') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Non-Dimmable Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 800) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 800 &&
            setupFieldChoices.stripLength <= 2000
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-30W.jpg';
          } else if (
            setupFieldChoices.stripLength > 2000 &&
            setupFieldChoices.stripLength <= 5200
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-75W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020007';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '180W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-nondim-IP20-180W.jpg';
          }
        }
        if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
          copyOfZoneLayout[0].layouts[0].fields[0].value =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].iprating = 'IP20';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
            'Dimmable (Triac) Driver';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
            'IP20 / 24V';
          if (setupFieldChoices.stripLength <= 1700) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020010';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '25W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-25W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1700 &&
            setupFieldChoices.stripLength <= 3400
          ) {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020011';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '50W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-50W.jpg';
          } else {
            copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020001';
            copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
            copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
              'driver-triac-IP20-75W.jpg';
           }
        }
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020067';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-144-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.10;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020068';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-144-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.10;
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020083';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-144-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.4;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020084';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-white-144-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.4;
      }
    }
  }

  if (setupFieldChoices.ledType === 'RGBW') {
    // Set number of pieces for strip
    copyOfZoneLayout[0].layouts[0].fields[2].quantity =
      setupFieldChoices.stripLength / 100;

    // Set validation divisor - min cut length
    copyOfZoneLayout[0].layouts[0].fields[2].options[0].validationdivisor = 100;

    // DC Cable / DC Cable Corner SKU: JC020092
    copyOfZoneLayout[0].layouts[0].fields[1].SKU = 'JC020092';
    copyOfZoneLayout[0].layouts[0].fields[1].options[0].price = 7;
    copyOfZoneLayout[0].layouts[0].fields[1].price = 7;

    if (setupFieldChoices.driverRequired === 'JCC Driver') {
      // Note all RGBW drivers are Non-dimmable
      copyOfZoneLayout[0].layouts[0].fields[0].value = 'Non-Dimmable Driver';
      copyOfZoneLayout[0].layouts[0].fields[0].options[0].title =
        'Non-Dimmable Driver';
      copyOfZoneLayout[0].layouts[0].fields[0].options[0].description =
        'IP20 / 24V';

      if (setupFieldChoices.wattage === 7.2) {
        if (setupFieldChoices.stripLength <= 1600) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 1600 &&
            setupFieldChoices.stripLength <= 4150
          ) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-30W.jpg';
        } else {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-75W.jpg';
        }
      }
      if (setupFieldChoices.wattage === 17.6) {
        if (setupFieldChoices.stripLength <= 650) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020009';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '12W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-12W.jpg';
          } else if (
            setupFieldChoices.stripLength > 650 &&
            setupFieldChoices.stripLength <= 2050
          ) {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020012';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '30W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-30W.jpg';
        } else {
          copyOfZoneLayout[0].layouts[0].fields[0].SKU = 'BC020013';
          copyOfZoneLayout[0].layouts[0].fields[0].wattage = '75W';
          copyOfZoneLayout[0].layouts[0].fields[0].options[0].imagepath =
            'driver-nondim-IP20-75W.jpg';
        }
      }
    }

    if (setupFieldChoices.wattage === 7.2) {
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020070';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-72-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.80;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020071';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-72-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.80;
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020086';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-72-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.3;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020087';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-72-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.3;
      }
    }

    if (setupFieldChoices.wattage === 17.6) {
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020072';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-176-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.4;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP20'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020073';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP20';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-176-IP20.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 3.4;
      }
      if (
        setupFieldChoices.colourTemp === '3000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020088';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-176-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.30;
      }
      if (
        setupFieldChoices.colourTemp === '4000K' &&
        setupFieldChoices.environment === 'IP65'
      ) {
        copyOfZoneLayout[0].layouts[0].fields[2].SKU = 'JC020089';
        copyOfZoneLayout[0].layouts[0].fields[2].iprating = 'IP65';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].imagepath =
          'straight-RGBW-176-IP65.jpg';
        copyOfZoneLayout[0].layouts[0].fields[2].options[0].price = 2.30;
      }
    }
  }

  if (setupFieldChoices.driverRequired === 'JCC Driver') {
    copyOfZoneLayout[0].layouts[0].fields[0].instruction =
      "Your driver type has been selected based on your setup choices. We'll automatically specify a driver with the most economical wattage rating for your layout based on the total length of your LED Strip.";
  } else {
    copyOfZoneLayout[0].layouts[0].fields[0].instruction =
      "You have selected to supply your own 24V constant voltage driver in your setup choices. We'll calculate the minimum wattage rating required for your layout based on the total length of your LED Strip.";
  }

  if (setupFieldChoices.ledType === 'RGBW') {
    if (
      setupFieldChoices.remoteType === 'Handheld Remote' &&
      setupFieldChoices.remoteQuantity > 0
    ) {
      copyOfZoneLayout[0].layouts[0].fields[0].options.push({
        title: 'Controller for Handheld Remote',
        description: 'Can power up to 3 zones',
        selected: true,
        deselected: false,
        imagepath: 'JC121448-controller-for-hand-held-remote.jpg',
        SKU: 'JC121448',
        price: 107.5,
      });

      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        copyOfZoneLayout[0].layouts[0].fields[0].instruction =
          "Your driver type has been selected based on your setup choices. We'll automatically specify a driver with the most economical wattage rating for your layout based on the total length of your LED Strip. 1m of DC Cable connecting the driver and controller will automatically be added to each layout.";
      } else {
        copyOfZoneLayout[0].layouts[0].fields[0].instruction =
          "You have selected to supply your own 24V constant voltage driver in your setup choices. We'll calculate the minimum wattage rating required for your layout based on the total length of your LED Strip. 1m of DC Cable connecting the driver and controller will automatically be added to each layout.";
      }
    }
    if (
      setupFieldChoices.remoteType === 'Wall Mounted Remote' &&
      setupFieldChoices.remoteQuantity > 0
    ) {
      copyOfZoneLayout[0].layouts[0].fields[0].options.push({
        title: 'Controller for Wall Mounted Remote',
        description: 'Can power up to 4 zones',
        selected: true,
        deselected: false,
        imagepath: 'JC121450-controller-for-wall-mounted-remote.jpg',
        SKU: 'JC121450',
        price: 94.5,
      });

      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        copyOfZoneLayout[0].layouts[0].fields[0].instruction =
          "Your driver type has been selected based on your setup choices. We'll automatically specify a driver with the most economical wattage rating for your layout based on the total length of your LED Strip. 1m of DC Cable connecting the driver and controller will automatically be added to each layout.";
      } else {
        copyOfZoneLayout[0].layouts[0].fields[0].instruction =
          "You have selected to supply your own 24V constant voltage driver in your setup choices. We'll calculate the minimum wattage rating required for your layout based on the total length of your LED Strip. 1m of DC Cable connecting the driver and controller will automatically be added to each layout.";
      }
    }
  }

  copyOfZoneLayout[0].layouts[0].maxLength = setupFieldChoices.validationlimit;
  copyOfZoneLayout[0].layouts[0].fields[2].options[0].validationlimit =
    setupFieldChoices.validationlimit;
  copyOfZoneLayout[0].layouts[0].lengthRemaining =
    setupFieldChoices.validationlimit - parseInt(setupFieldChoices.stripLength);

  copyOfZoneLayout[0].layouts[0].stripLength = parseInt(
    setupFieldChoices.stripLength
  );
  copyOfZoneLayout[0].layouts[0].fields[2].ledtype = setupFieldChoices.ledType;
  copyOfZoneLayout[0].layouts[0].fields[2].length =
    setupFieldChoices.stripLength;
  copyOfZoneLayout[0].layouts[0].fields[2].wattage =
    setupFieldChoices.wattage + 'W';
  copyOfZoneLayout[0].layouts[0].fields[2].iprating =
    setupFieldChoices.environment;
  copyOfZoneLayout[0].layouts[0].fields[2].colourtemp =
    setupFieldChoices.colourTemp;
  copyOfZoneLayout[0].layouts[0].fields[2].price =
    copyOfZoneLayout[0].layouts[0].fields[2].options[0].price *
    (copyOfZoneLayout[0].layouts[0].fields[2].length /
      copyOfZoneLayout[0].layouts[0].fields[2].options[0].validationdivisor);

// console.log('price: ', copyOfZoneLayout[0].layouts[0].fields[2].price);
// console.log('options price: ', copyOfZoneLayout[0].layouts[0].fields[2].options[0].price);
// console.log('length: ', copyOfZoneLayout[0].layouts[0].fields[2].length);
// console.log('validation divisor: ', copyOfZoneLayout[0].layouts[0].fields[2].options[0].validationdivisor);

  copyOfZoneLayout[0].layouts[0].fields[2].options[0].length =
    setupFieldChoices.stripLength;
  copyOfZoneLayout[0].layouts[0].fields[2].options[0].description =
    setupFieldChoices.wattage +
    'W / ' +
    setupFieldChoices.colourTemp +
    ' / ' +
    setupFieldChoices.environment;

  return copyOfZoneLayout;
}

export default CreateSingleLengthZoneLayout;
